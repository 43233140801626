import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import ContentArea from "components/ContentArea";
import Image from "components/Image";
import useIsInEditMode from "hooks/useIsInEditMode";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import {
    BlocksWrapper,
    Container,
    ImageWrapper,
    StyledSection,
} from "./SideBySideFullBleed.styled";
import SideBySideFullBleedProps from "./SideBySideFullBleedProps";

const SideBySideFullBleed = ({
    identifier,
    displayOption = "Left",
    theme = "lightgray",
    contentTheme = "black",
    image,
    blocks,
    anchorName,
}: SideBySideFullBleedProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    const headingRight = displayOption === "Right";

    if (image && image.url) {
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
            content: [
                {
                    width: 2560,
                    breakpoint: BREAKPOINT_NUMBER.XXXL,
                },
                {
                    width: 1920,
                    breakpoint: BREAKPOINT_NUMBER.XXL,
                },
                {
                    width: 1680,
                    breakpoint: BREAKPOINT_NUMBER.XL,
                },
                {
                    width: 1365,
                    breakpoint: BREAKPOINT_NUMBER.L,
                },
                {
                    width: 992,
                    breakpoint: BREAKPOINT_NUMBER.M,
                },
                {
                    width: 768,
                    height: 768,
                },
            ],
        });
    }

    return (
        <StyledSection colorTheme={theme} id={anchorName}>
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    displayOption: displayOption,
                    headingRight: headingRight,
                    hasImage: !!(image && image.url),
                    blockInBlock: true,
                    isInEditMode: isInEditMode,
                }}
            >
                <Container key={identifier || `SideBySideFullBleedContent`}>
                    {image?.url && (
                        <ImageWrapper>
                            <Image {...image} caption="" cover={true} />
                        </ImageWrapper>
                    )}
                    {blocks && (
                        <BlocksWrapper>
                            <ContentArea
                                items={blocks}
                                componentSelector={getComponentTypeForContent}
                            />
                        </BlocksWrapper>
                    )}
                </Container>
            </ThemeProvider>
        </StyledSection>
    );
};

export default React.memo(SideBySideFullBleed);
