import { styled, css } from "styled-components";
import theme from "styled-theming";

import Section from "components/Section";
import { Container as GridContainer } from "style/components/Page";
import { columnGap, GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";

const backgroundColor = theme("theme", {
    undefined: "--color-transparent",
    default: "--background-default",
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
    coral: "--block-coral-default",
});

export const StyledSection = styled(Section)`
    --image-width: calc(50vw - (0.5 * var(--grid-column-gutter)));

    align-items: center;
    position: relative;

    ${MQ.FROM_L} {
        --image-width: calc(
            50vw - var(--grid-column-size) - (1.5 * var(--grid-column-gutter)) -
                (0.5 * 17px) - var(--grid-column-gutter) - var(
                    --grid-column-size
                )
        );
    }
`;

export const Container = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);

    ${MQ.FROM_L} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${theme("isInEditMode", {
        true: css`
            min-height: auto;

            ${MQ.FROM_L} {
                min-height: auto;
            }
        `,
    })}
`;

export const BlocksWrapper = styled.div`
    background-color: var(${backgroundColor});
    grid-column: col-start 1 / span 4;
    grid-row: 1;

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 8;

        ${theme("displayOption", {
            Right: css`
                grid-column: col-start 5 / span 8;
            `,
        })}
    }
`;

export const ImageWrapper = styled.div`
    aspect-ratio: 1;
    display: block;
    grid-column: col-start 1 / span 4;
    margin: 0 -${GUTTER.SM} -3.5rem;
    position: relative;

    ${MQ.FROM_M} {
        margin: 0 -${GUTTER.MD} -6.125rem;
    }

    ${MQ.FROM_L} {
        aspect-ratio: unset;
        bottom: 0;
        grid-row: 1 / span 3;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: var(--image-width);

        ${theme("displayOption", {
            Right: css`
                left: 0;
                right: auto;
            `,
        })}
    }
`;
